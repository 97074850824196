import { db } from './firebase';
import { doc, setDoc } from 'firebase/firestore';

export const mockPlayers = [
    {
      fighter_name: "Pixel Puncher",
      fighter_description: "A retro-inspired warrior who fights with 8-bit energy blasts",
      avatar_url: "https://placehold.co/96x96/666666/FFFFFF/png?text=PP",
      illustration_url: "https://placehold.co/800x600/444444/FFFFFF/png?text=Pixel+Puncher"
    },
    {
      fighter_name: "Neon Ninja",
      fighter_description: "A cyberpunk assassin who moves like lightning",
      avatar_url: "https://placehold.co/96x96/666666/FFFFFF/png?text=NN",
      illustration_url: "https://placehold.co/800x600/444444/FFFFFF/png?text=Neon+Ninja"
    },
    {
      fighter_name: "Quantum Queen",
      fighter_description: "A scientific genius who manipulates space-time",
      avatar_url: "https://placehold.co/96x96/666666/FFFFFF/png?text=QQ",
      illustration_url: "https://placehold.co/800x600/444444/FFFFFF/png?text=Quantum+Queen"
    }
  ];

const mockMatch = {
  player_id_1: 0,
  player_id_2: 1,
  votes_1: 0,
  votes_2: 0,
  winner: -1,
  initial_action_text_1: "Fighter 1 prepares for battle!",
  initial_action_image_1: "/api/placeholder/800/600",
  initial_action_tts_1: "match-audio/initial-1.mp3",
  initial_action_text_2: "Fighter 2 responds with determination!",
  initial_action_image_2: "/api/placeholder/800/600",
  initial_action_tts_2: "match-audio/initial-2.mp3",
  initial_action_resolution_text: "The fighters size each other up...",
  initial_action_resolution_image: "/api/placeholder/800/600",
  initial_action_resolution_tts: "match-audio/initial-resolution.mp3",
  final_action_text_1: "Fighter 1 launches a powerful attack!",
  final_action_image_1: "/api/placeholder/800/600",
  final_action_tts_1: "match-audio/final-1.mp3",
  final_action_text_2: "Fighter 2 counters with magic!",
  final_action_image_2: "/api/placeholder/800/600",
  final_action_tts_2: "match-audio/final-2.mp3",
  final_action_resolution_text_1: "Fighter 1 emerges victorious!",
  final_action_resolution_image_1: "/api/placeholder/800/600",
  final_action_resolution_tts_1: "match-audio/resolution-1.mp3",
  final_action_resolution_text_2: "Fighter 2 admits defeat...",
  final_action_resolution_image_2: "/api/placeholder/800/600",
  final_action_resolution_tts_2: "match-audio/resolution-2.mp3"
};

const mockGameData = {
  started: false,
  voting: -1,
  players: mockPlayers.slice(0, 2),
  matches: [mockMatch],
  login_code: "TEST1"
};

const mockBracketData = {
  players: mockPlayers,
  matches: [
    // First round
    {
      ...mockMatch,
      player_id_1: 0,
      player_id_2: 1,
      votes_1: 3,
      votes_2: 2,
      winner: 0
    },
    {
      ...mockMatch,
      player_id_1: 2,
      player_id_2: 3,
      votes_1: 4,
      votes_2: 1,
      winner: 2
    },
    // Finals
    {
      ...mockMatch,
      player_id_1: 0,
      player_id_2: 2,
      votes_1: 0,
      votes_2: 0,
      winner: -1
    }
  ],
  started: true,
  voting: 2  // Final match is active
};

const mockMatchScreenData = {
  players: mockPlayers,
  matches: [mockMatch]
};

export const mockDocIds = {
  waitingGame: 'dev-waiting-game',
  votingGame: 'dev-voting-game',
  lobbyGame: 'dev-lobby-game',
  bracketGame: 'dev-bracket-game',
  matchGame: 'dev-match-game'
};

// Initialize Firestore with mock data
export const initDevFirestore = async () => {
  try {
    // Waiting screen test doc
    await setDoc(doc(db, 'games', mockDocIds.waitingGame), mockGameData);

    // Voting screen test doc
    await setDoc(doc(db, 'games', mockDocIds.votingGame), {
      ...mockGameData,
      started: true,
      voting: 0,
    });

    // Lobby test doc
    await setDoc(doc(db, 'games', mockDocIds.lobbyGame), {
      ...mockGameData,
      players: mockPlayers.slice(0, 2),
    });

    // Bracket test doc
    await setDoc(doc(db, 'games', mockDocIds.bracketGame), mockBracketData);

    // Match screen test doc
    await setDoc(doc(db, 'games', mockDocIds.matchGame), mockMatchScreenData);

    console.log('Dev Firestore initialized successfully');
  } catch (error) {
    console.error('Error initializing dev Firestore:', error);
  }
};