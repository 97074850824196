import React from 'react';
import Christmas from './Christmas';

const COOKING_EMOJIS = ['👩‍🍳', '👨‍🍳', '🍳', '🥘', '🍝', '🥖', '🧑‍🍳', '🍷', '🥗', '🍕'];

const CORNER_DECORATIONS = [
  '👩‍🍳 🍳 🥘', // top-left
  '🍝 🥖 👨‍🍳', // top-right
  '🎁 🎄 🍷', // bottom-left
  '⭐ 🥗 🍕'  // bottom-right
];

const VIDEO_URL = "https://storage.googleapis.com/choose-your-fighter-8/Gen-3%20Alpha%20Turbo%203688031587%2C%20Two%20people%20walk%20into%2C%20Cropped%20-%20450846820_%2C%20M%205.mp4";
const CERTIFICATE_URL = "https://storage.googleapis.com/choose-your-fighter-8/Screenshot%202024-12-13%20180419.png";
const VOUCHER_URL = "https://bookeo.com/lacucina/voucher?code=6U9CLHT";
const CLASSES_URL = "https://www.lacucinaatthemarket.com/classes.html";

export default function ChristmasSamantha() {
  const handleBooking = () => {
    window.open(CLASSES_URL, '_blank');
  };

  const handleCertificateClick = () => {
    window.open(VOUCHER_URL, '_blank');
  };

  return (
    <Christmas
      title="Samantha and Jake"
      videoUrl={VIDEO_URL}
      certificateUrls={[CERTIFICATE_URL]}
      themeEmojis={COOKING_EMOJIS}
      onBooking={handleBooking}
      cornerEmojis={CORNER_DECORATIONS}
      onCertificateClick={handleCertificateClick}
    />
  );
}