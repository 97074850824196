import React, { useState, useEffect, useRef } from 'react';

const ANIMATIONS = `
  @keyframes fall {
    0% { transform: translateY(-10vh) rotate(0deg); }
    100% { transform: translateY(110vh) rotate(360deg); }
  }

  @keyframes spin {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
  }

  @keyframes pulse {
    0%, 100% { opacity: 1; transform: scale(1); }
    50% { opacity: 0.8; transform: scale(0.95); }
  }

  @keyframes bounce {
    0%, 100% { transform: translateY(0); }
    50% { transform: translateY(-20px); }
  }

  @keyframes borderGlow {
    0%, 100% { box-shadow: 0 0 20px rgba(253, 224, 71, 0.5); }
    50% { box-shadow: 0 0 40px rgba(253, 224, 71, 0.8); }
  }

  @keyframes fallDown {
    from { transform: translateY(0); }
    to { transform: translateY(110vh); }
  }

  @keyframes sway {
    0%, 100% { margin-left: 0; }
    50% { margin-left: 50px; }
  }

  @keyframes santaFlight {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
      left: -10%;
      top: 30%;
    }
    25% {
      transform: translate(-50%, -50%) rotate(-10deg);
      left: 50%;
      top: 20%;
    }
    50% {
      transform: translate(-50%, -50%) rotate(0deg);
      left: 110%;
      top: 30%;
    }
    75% {
      transform: translate(-50%, -50%) rotate(10deg);
      left: 50%;
      top: 40%;
    }
    100% {
      transform: translate(-50%, -50%) rotate(0deg);
      left: -10%;
      top: 30%;
    }
  }

  @keyframes sleighBob {
    0%, 100% { transform: translateY(0) rotate(var(--rotation)); }
    50% { transform: translateY(-5px) rotate(var(--rotation)); }
  }

  @keyframes sparkleTrail {
    0% { opacity: 1; transform: scale(1); }
    100% { opacity: 0; transform: scale(0.3); }
  }
`;

const SantaSleigh = () => {
  const [sparkles, setSparkles] = useState([]);
  
  useEffect(() => {
    const interval = setInterval(() => {
      setSparkles(prev => {
        const newSparkles = [...prev, {
          id: Date.now(),
          left: Math.random() * 100,
          top: Math.random() * 100,
        }];
        return newSparkles.slice(-20);
      });
    }, 200);

    return () => clearInterval(interval);
  }, []);

  return (
    <div style={{
      position: 'absolute',
      width: '100%',
      height: '100%',
      pointerEvents: 'none',
      zIndex: 10,
    }}>
      <div style={{
        position: 'absolute',
        animation: 'santaFlight 20s linear infinite',
        fontSize: '3rem',
        whiteSpace: 'nowrap',
        zIndex: 1000,
      }}>
        🎅🛷
        <span style={{ marginLeft: '5px', fontSize: '2.5rem' }}>🦌🦌🦌</span>
      </div>
      
      {sparkles.map((sparkle) => (
        <div
          key={sparkle.id}
          style={{
            position: 'absolute',
            left: `${sparkle.left}%`,
            top: `${sparkle.top}%`,
            fontSize: '1rem',
            animation: 'sparkleTrail 1s linear forwards',
          }}
        >
          ✨
        </div>
      ))}
    </div>
  );
};

export const Christmas = ({
  title,
  videoUrl,
  certificateUrls,
  themeEmojis,
  onBooking,
  cornerEmojis,
  couponCode,
  onCertificateClick,
  showBook = true,
}) => {
  const [snowflakes, setSnowflakes] = useState([]);
  const [currentState, setCurrentState] = useState('INITIAL');
  const [certificateRevealed, setCertificateRevealed] = useState(false);
  const [currentCertificateIndex, setCurrentCertificateIndex] = useState(0);
  const [themeConfetti, setThemeConfetti] = useState([]);
  const videoRef = useRef(null);
  
  useEffect(() => {
    const interval = setInterval(() => {
      setSnowflakes(prev => {
        const newSnowflakes = [...prev];
        if (newSnowflakes.length < 50) {
          newSnowflakes.push({
            id: Date.now(),
            left: Math.random() * 100,
            animationDuration: 5 + Math.random() * 5,
            opacity: 0.5 + Math.random() * 0.5,
            size: 10 + Math.random() * 20
          });
        }
        return newSnowflakes.slice(-50);
      });
    }, 200);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (certificateRevealed) {
      const initialConfetti = [...Array(30)].map(() => ({
        id: Math.random(),
        emoji: themeEmojis[Math.floor(Math.random() * themeEmojis.length)],
        left: Math.random() * 100,
        speed: 8 + Math.random() * 4,
        size: 30 + Math.random() * 20,
        rotation: Math.random() * 360,
        sway: -50 + Math.random() * 100,
        delay: Math.random()
      }));
      setThemeConfetti(initialConfetti);

      const interval = setInterval(() => {
        setThemeConfetti(prev => {
          if (prev.length >= 50) return prev;
          return [...prev, {
            id: Math.random(),
            emoji: themeEmojis[Math.floor(Math.random() * themeEmojis.length)],
            left: Math.random() * 100,
            speed: 8 + Math.random() * 4,
            size: 30 + Math.random() * 20,
            rotation: Math.random() * 360,
            sway: -50 + Math.random() * 100,
            delay: 0
          }];
        });
      }, 200);

      return () => clearInterval(interval);
    }
  }, [certificateRevealed, themeEmojis]);

  const handleUnwrap = () => {
    setCurrentState('PLAYING_VIDEO');
  };

  const handleContinueToGift = () => {
    setCurrentState('SHOWING_GIFT');
    setTimeout(() => setCertificateRevealed(true), 500);
  };

  const handleReplayVideo = () => {
    if (videoRef.current) {
      videoRef.current.currentTime = 0;
      videoRef.current.play();
    }
  };

  const handleShowCoupon = () => {
    setCurrentState('SHOWING_COUPON');
  };

  const handleNextCertificate = () => {
    if (currentCertificateIndex < certificateUrls.length - 1) {
      setCurrentCertificateIndex(prev => prev + 1);
    } else if (!couponCode && onBooking) {
      onBooking();
    }
  };

  const handlePrevCertificate = () => {
    setCurrentCertificateIndex(prev => Math.max(0, prev - 1));
  };

  const renderThemeConfetti = () => (
    <div style={{ position: 'fixed', inset: 0, pointerEvents: 'none', zIndex: 1100 }}>
      {themeConfetti.map(confetti => (
        <div
          key={confetti.id}
          style={{
            position: 'absolute',
            left: `${confetti.left}%`,
            top: '-10%',
            fontSize: `${confetti.size}px`,
            pointerEvents: 'none',
            transform: 'translateY(0)',
            animation: `
              fallDown ${confetti.speed}s linear forwards,
              sway ${confetti.speed}s ease-in-out infinite
            `
          }}
        >
          {confetti.emoji}
        </div>
      ))}
    </div>
  );

  const renderVideo = () => (
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.9)',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 1000,
      gap: '20px'
    }}>
      <video
        ref={videoRef}
        src={videoUrl}
        style={{
          maxWidth: '90%',
          maxHeight: '70vh',
          borderRadius: '12px',
          border: '4px solid #fde047',
          boxShadow: '0 0 30px rgba(253, 224, 71, 0.3)'
        }}
        autoPlay
        controls
      />
      <div style={{ display: 'flex', gap: '15px' }}>
        <button
          onClick={handleContinueToGift}
          style={{
            padding: '10px 20px',
            backgroundColor: '#dc2626',
            color: 'white',
            border: '2px solid #fde047',
            borderRadius: '20px',
            cursor: 'pointer',
            fontSize: '1rem',
            transition: 'all 0.3s ease'
          }}
          onMouseOver={(e) => e.currentTarget.style.transform = 'scale(1.05)'}
          onMouseOut={(e) => e.currentTarget.style.transform = 'scale(1)'}
        >
          🎁 Unwrap Your Gift
        </button>
      </div>
    </div>
  );

  const renderCertificate = () => (
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.9)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 1000
    }}>
      {renderThemeConfetti()}
      {certificateRevealed && (
        <div style={{
          position: 'relative',
          maxWidth: '90%',
          maxHeight: '90vh',
          opacity: 1,
          transform: 'scale(1)',
          transition: 'all 1s cubic-bezier(0.34, 1.56, 0.64, 1)',
        }}>
          <div style={{
            position: 'absolute',
            inset: '-20px',
            border: '3px solid #fde047',
            borderRadius: '15px',
            animation: 'borderGlow 2s infinite',
            pointerEvents: 'none'
          }} />
          {themeEmojis.slice(0, 4).map((emoji, index) => (
            <div
              key={index}
              style={{
                position: 'absolute',
                fontSize: '2rem',
                animation: 'bounce 2s infinite',
                animationDelay: `${index * 0.2}s`,
                ...(index === 0 && { top: '-30px', left: '-30px' }),
                ...(index === 1 && { top: '-30px', right: '-30px' }),
                ...(index === 2 && { bottom: '-30px', left: '-30px' }),
                ...(index === 3 && { bottom: '-30px', right: '-30px' }),
              }}
            >
              {emoji}
            </div>
          ))}
          <img
            src={certificateUrls[currentCertificateIndex]}
            alt="Gift Certificate"
            onClick={() => onCertificateClick?.(currentCertificateIndex)}
            style={{
              maxWidth: '100%',
              maxHeight: '80vh',
              borderRadius: '10px',
              boxShadow: '0 0 30px rgba(253, 224, 71, 0.3)',
              cursor: 'pointer'
            }}
          />
          <div style={{
            position: 'absolute',
            bottom: '-60px',
            left: '50%',
            transform: 'translateX(-50%)',
            display: 'flex',
            gap: '20px'
          }}>
            {currentCertificateIndex > 0 && (
              <button
                onClick={handlePrevCertificate}
                style={{
                  padding: '10px 20px',
                  backgroundColor: '#166534',
                  color: 'white',
                  border: '2px solid #fde047',
                  borderRadius: '20px',
                  cursor: 'pointer',
                  fontSize: '1rem'
                }}
              >
                ⬅️ Previous
              </button>
            )}
            {currentCertificateIndex === certificateUrls.length - 1 ? (
              couponCode ? (
                <button
                  onClick={handleShowCoupon}
                  style={{
                    padding: '10px 20px',
                    backgroundColor: '#166534',
                    color: 'white',
                    border: '2px solid #fde047',
                    borderRadius: '20px',
                    cursor: 'pointer',
                    fontSize: '1rem'
                  }}
                >
                  📝 View Coupon Code
                </button>
              ) : ( showBook &&
                <button
                  onClick={onBooking}
                  style={{
                    padding: '10px 20px',
                    backgroundColor: '#166534',
                    color: 'white',
                    border: '2px solid #fde047',
                    borderRadius: '20px',
                    cursor: 'pointer',
                    fontSize: '1rem'
                  }}
                >
                  🎟️ Book Your Visit! 🎟️
                </button>
              )
            ) : (
              <button
                onClick={handleNextCertificate}
                style={{
                  padding: '10px 20px',
                  backgroundColor: '#166534',
                  color: 'white',
                  border: '2px solid #fde047',
                  borderRadius: '20px',
                  cursor: 'pointer',
                  fontSize: '1rem'
                }}
              >
                Next ➡️
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );

  const renderCoupon = () => (
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.9)',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 1000,
      gap: '30px'
    }}>
      <div style={{
        backgroundColor: 'rgba(255, 255, 255, 0.1)',
        padding: '30px 50px',
        borderRadius: '15px',
        border: '3px solid #fde047',
        textAlign: 'center'
      }}>
        <h2 style={{
          color: '#fde047',
          fontSize: '2rem',
          marginBottom: '15px'
        }}>
          Your Coupon Code:
        </h2>
        <div style={{
          color: 'white',
          fontSize: '3rem',
          fontFamily: 'monospace',
          letterSpacing: '2px',
          marginBottom: '20px'
        }}>
          {couponCode}
        </div>
      </div>
      <button
        onClick={onBooking}
        style={{
          padding: '15px 30px',
          backgroundColor: '#dc2626',
          color: 'white',
          border: '2px solid #fde047',
          borderRadius: '25px',
          cursor: 'pointer',
          fontSize: '1.2rem',
          transition: 'all 0.3s ease'
        }}
        onMouseOver={(e) => e.currentTarget.style.transform = 'scale(1.05)'}
        onMouseOut={(e) => e.currentTarget.style.transform = 'scale(1)'}
      >
        🍷 Book Your Visit 🍷
      </button>
    </div>
  );

  const renderInitialState = () => (
    <>
      {/* Santa Animation Container */}
      <div style={{
        position: 'fixed',
        inset: 0,
        pointerEvents: 'none',
        zIndex: 2
      }}>
        <div style={{
          position: 'absolute',
          animation: 'santaFlight 20s linear infinite',
          fontSize: '3rem',
          whiteSpace: 'nowrap',
        }}>
          🎅🛷
          <span style={{ marginLeft: '5px', fontSize: '2.5rem' }}>🦌🦌🦌</span>
        </div>
      </div>

      {/* Main Content Container */}
      <div style={{
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '100%',
        textAlign: 'center',
        padding: '20px',
        zIndex: 3
      }}>
        <h1 style={{
          fontSize: '4rem',
          color: '#ff3333', // Bright Christmas red
          marginBottom: '2rem',
          animation: 'bounce 2s infinite',
          textShadow: `
            0 0 5px #fff,
            0 0 10px #fff,
            0 0 15px #fff,
            0 0 20px #ffffff,
            0 0 35px #ffffff,
            0 0 40px #ffffff,
            0 0 50px #ffffff
          `
        }}>
          🎄✨ Merry Christmas! ✨🎄
        </h1>
        
        <div style={{
          fontSize: '2.5rem',
          color: 'white',
          marginBottom: '3rem',
          animation: 'pulse 2s infinite'
        }}>
          🎁 {title} 🎁
        </div>

        <div style={{ position: 'relative', display: 'inline-block' }}>
          <button 
            onClick={handleUnwrap}
            style={{
              backgroundColor: '#dc2626',
              color: 'white',
              padding: '1rem 2rem',
              borderRadius: '9999px',
              border: '4px solid #fde047',
              fontSize: '1.25rem',
              fontWeight: 'bold',
              cursor: 'pointer',
              animation: 'bounce 2s infinite',
              boxShadow: '0 0 20px rgba(255, 255, 255, 0.5)',
              transition: 'transform 0.3s'
            }}
            onMouseOver={(e) => e.currentTarget.style.transform = 'scale(1.05)'}
            onMouseOut={(e) => e.currentTarget.style.transform = 'scale(1)'}
          >
            🎁 Unwrap Your Gift! 🎁
          </button>
        </div>
      </div>
    </>
  );

  const renderContent = () => {
    switch (currentState) {
      case 'PLAYING_VIDEO':
        return renderVideo();
      case 'SHOWING_GIFT':
        return renderCertificate();
      case 'SHOWING_COUPON':
        return renderCoupon();
      default:
        return renderInitialState();
    }
  };

  return (
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100vw',
      height: '100vh',
      background: 'linear-gradient(to bottom, #dc2626, #166534)',
      overflow: 'hidden'
    }}>
      {/* Snowflakes */}
      <div style={{ position: 'fixed', inset: 0, pointerEvents: 'none', zIndex: 1 }}>
        {snowflakes.map((snowflake) => (
          <div
            key={snowflake.id}
            style={{
              position: 'absolute',
              left: `${snowflake.left}%`,
              top: '-20px',
              color: 'white',
              fontSize: `${snowflake.size}px`,
              opacity: snowflake.opacity,
              animation: `fall ${snowflake.animationDuration}s linear forwards`
            }}
          >
            ❄
          </div>
        ))}
      </div>

      {/* Corner Decorations */}
      <div style={{ position: 'fixed', inset: 0, pointerEvents: 'none', zIndex: 4 }}>
        {cornerEmojis?.map((group, index) => {
          const positions = [
            { top: '20px', left: '20px' },
            { top: '20px', right: '20px' },
            { bottom: '20px', left: '20px' },
            { bottom: '20px', right: '20px' }
          ];
          return (
            <div 
              key={index}
              style={{ 
                position: 'absolute',
                fontSize: '3rem',
                ...positions[index]
              }}
            >
              {group}
            </div>
          );
        })}
      </div>

      {/* Main Content States */}
      <div style={{ position: 'relative', width: '100%', height: '100%', zIndex: 5 }}>
        {currentState === 'INITIAL' && renderInitialState()}
        {currentState === 'PLAYING_VIDEO' && renderVideo()}
        {currentState === 'SHOWING_GIFT' && renderCertificate()}
        {currentState === 'SHOWING_COUPON' && renderCoupon()}
      </div>

      {/* Theme Confetti */}
      {certificateRevealed && (
        <div style={{ 
          position: 'fixed', 
          inset: 0, 
          pointerEvents: 'none', 
          zIndex: 1100 
        }}>
          {themeConfetti.map(confetti => (
            <div
              key={confetti.id}
              style={{
                position: 'absolute',
                left: `${confetti.left}%`,
                top: '-10%',
                fontSize: `${confetti.size}px`,
                transform: 'translateY(0)',
                animation: `
                  fallDown ${confetti.speed}s linear forwards,
                  sway ${confetti.speed}s ease-in-out infinite
                `
              }}
            >
              {confetti.emoji}
            </div>
          ))}
        </div>
      )}

      {/* Global Animations */}
      <style>{ANIMATIONS}</style>
    </div>
  );

};

export default Christmas;