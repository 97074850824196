import React from 'react';
import { CssBaseline } from '@mui/material';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';
import ArcadeEntry from './ArcadeEntry';
import HostEntry from './HostEntry';
import { devRoutes } from './DevRoutes';
import ChristmasSamantha from './ChristmasSamantha';
import ChristmasMarissa from './ChristmasMarissa';
import ChristmasMomDad from './ChristmasMomDad';
import ChristmasMom from './ChristmasMom';
import ChristmasDad from './ChristmasDad';


const App = () => {
  return (
    <Router>
      <CssBaseline />
      <Routes>
        <Route path="/host" element={<HostEntry />}/>
        <Route path="/samantha" element={<ChristmasSamantha />}/>
        <Route path="/marissa" element={<ChristmasMarissa />}/>
        <Route path="/momdad" element={<ChristmasMomDad />}/>
        <Route path="/mom" element={<ChristmasMom />}/>
        <Route path="/dad" element={<ChristmasDad />}/>
        <Route path="/" element={<ArcadeEntry />}/>
        {process.env.NODE_ENV === 'development' && devRoutes}
      </Routes>
    </Router>
  );
};

export default App;