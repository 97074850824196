import React from 'react';
import Christmas from './Christmas';

const FARM_EMOJIS = ['🐮', '🐷', '🐔', '🚜', '🌾', '🐑', '🐴', '🐓', '🌻', '🥕'];

const CORNER_DECORATIONS = [
  '🐮 🐷 🐑', // top-left
  '🚜 🌾 🐔', // top-right
  '🎁 🎄 🐴', // bottom-left
  '⭐ 🌟 🐓'  // bottom-right
];

const VIDEO_URL = "https://storage.googleapis.com/choose-your-fighter-8/Gen-3%20Alpha%20Turbo%203828236132%2C%20Farm%20animals%20(cows%2C%20%2C%20Cropped%20-%20IMG_202412%2C%20M%205.mp4";
const CERTIFICATE_URLS = [
  "https://storage.googleapis.com/choose-your-fighter-8/Screenshot%202024-12-13%20185908.png",
  "https://storage.googleapis.com/choose-your-fighter-8/Screenshot%202024-12-13%20190112.png"
];

// Gift card booking URL
const GIFT_CARD_URL = "https://fareharbor.com/embeds/book/rosebridgefarmandsanctuary/gift-cards/2RUUA-43B9W-353HG-8PJMA/?language=en-us";

// Regular booking URL
const BOOKING_URL = "https://fareharbor.com/embeds/book/rosebridgefarmandsanctuary/items/?full-items=yes";

export default function ChristmasMarissa() {
  const handleBooking = () => {
    window.open(BOOKING_URL, '_blank');
  };

  const handleCertificateClick = (index) => {
    if (index === 0) {  // Only open gift card URL on first certificate
      window.open(GIFT_CARD_URL, '_blank');
    }
  };

  return (
    <Christmas
      title="Marissa and Nic"
      videoUrl={VIDEO_URL}
      certificateUrls={CERTIFICATE_URLS}
      themeEmojis={FARM_EMOJIS}
      onBooking={handleBooking}
      cornerEmojis={CORNER_DECORATIONS}
      onCertificateClick={handleCertificateClick}
    />
  );
}