import React from 'react';
import Christmas from './Christmas';

const CRAFT_EMOJIS = ['🧶', '✂️', '🪡', '🎨', '📏', '🖌️', '🪜', '🔨', '🪚', '🎭'];

const CORNER_DECORATIONS = [
  '🧶 ✂️ 🪡', // top-left
  '🎨 📏 🖌️', // top-right
  '🎁 🎄 🪜', // bottom-left
  '⭐ 🔨 🪚'  // bottom-right
];

const VIDEO_URL = "https://storage.googleapis.com/choose-your-fighter-8/Gen-3%20Alpha%20Turbo%203530415015%2C%20The%20man%20disappears%20%2C%20468196251_1023236718%2C%20M%205.mp4";
const CERTIFICATE_URLS = [
  "https://storage.googleapis.com/choose-your-fighter-8/Screenshot%202024-12-14%20184235.png",
  "https://images.squarespace-cdn.com/content/v1/53dff4d6e4b080a853debd62/1669163314804-MRJO5JIA2BIELR7PY4E5/FullSizeRender+81.jpg?format=2500w"
];
const BOOKING_URL = "https://www.weaverhouseco.com/workshop-registration";
const COUPON_CODE = "GC2J-EFE8-WEGD-8DZR";

export default function ChristmasMom() {
  const handleBooking = () => {
    window.open(BOOKING_URL, '_blank');
  };

  return (
    <Christmas
      title="Mom"
      videoUrl={VIDEO_URL}
      certificateUrls={CERTIFICATE_URLS}
      themeEmojis={CRAFT_EMOJIS}
      onBooking={handleBooking}
      cornerEmojis={CORNER_DECORATIONS}
      couponCode={COUPON_CODE}
    />
  );
}