import React from 'react';
import { Typography } from '@mui/material';

// Main title typography (large, glowing, with shine animation)
export const ArcadeTitle = ({ children, sx = {} }) => {
    // Extract color from sx or use default
    const color = sx.color || '#ff6b00';
    // Create new sx without the color to avoid conflict
    const { color: _, ...restSx } = sx;
  
    return (
      <Typography 
        variant="h1" 
        sx={{
          fontSize: { xs: '2rem', sm: '3rem', md: '4rem' },
          fontFamily: '"Press Start 2P", system-ui',
          color: color,
          textAlign: 'center',
          textTransform: 'uppercase',
          position: 'relative',
          padding: 2,
          textShadow: `
            2px 2px 0 #000,
            -2px -2px 0 #000,
            2px -2px 0 #000,
            -2px 2px 0 #000,
            0 0 8px ${color}80,
            0 0 16px ${color}40
          `,
          animation: 'pulse 2s infinite',
          '@keyframes pulse': {
            '0%, 100%': {
              filter: 'brightness(1)',
            },
            '50%': {
              filter: 'brightness(1.2)',
            }
          },
          ...restSx
        }}
      >
        {children}
      </Typography>
    );
  };
// Subtitle with pulsing animation
export const ArcadeSubtitle = ({ children, sx = {} }) => (
  <Typography
    variant="h2"
    sx={{
      fontSize: { xs: '1.5rem', sm: '2rem', md: '2.5rem' },
      fontFamily: '"Press Start 2P", system-ui',
      color: '#ff6b00',
      textAlign: 'center',
      animation: 'pulse 1.5s infinite',
      textTransform: 'uppercase',
      padding: '0 20px',
      textShadow: '0 0 10px rgba(255, 107, 0, 0.5)',
      '@keyframes pulse': {
        '0%, 100%': {
          textShadow: '0 0 10px rgba(255, 107, 0, 0.5), 0 0 20px rgba(255, 107, 0, 0.3)'
        },
        '50%': {
          textShadow: '0 0 20px rgba(255, 107, 0, 0.8), 0 0 30px rgba(255, 107, 0, 0.5)'
        }
      },
      ...sx
    }}
  >
    {children}
  </Typography>
);

// Body text with retro style
export const ArcadeText = ({ children, sx = {} }) => (
  <Typography
    sx={{
      fontSize: { xs: '1rem', sm: '1.2rem' },
      fontFamily: '"Press Start 2P", system-ui',
      color: '#ff6b00',
      textAlign: 'center',
      textShadow: '0 0 5px rgba(255, 107, 0, 0.3)',
      ...sx
    }}
  >
    {children}
  </Typography>
);

// Glowing text for highlights or alerts
export const ArcadeGlow = ({ children, sx = {} }) => (
  <Typography
    sx={{
      fontSize: { xs: '1.2rem', sm: '1.5rem' },
      fontFamily: '"Press Start 2P", system-ui',
      color: '#ff6b00',
      textAlign: 'center',
      animation: 'glow 2s infinite',
      textShadow: '0 0 10px rgba(255, 107, 0, 0.5)',
      '@keyframes glow': {
        '0%, 100%': { opacity: 1 },
        '50%': { opacity: 0.7 }
      },
      ...sx
    }}
  >
    {children}
  </Typography>
);

export const ArcadeDots = ({ sx = {}, color = '#ff6b00' }) => (
    <Typography
      component="div"
      sx={{
        position: 'relative',
        width: '80px',
        height: '80px',
        mt: 4,
        '&::before, &::after': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          border: `4px solid ${color}`,
          borderRadius: '4px',
          animation: 'arcadeLoading 2s infinite',
          opacity: 0.8,
        },
        '&::after': {
          animation: 'arcadeLoading 2s infinite 1s',
        },
        '@keyframes arcadeLoading': {
          '0%': {
            transform: 'rotate(0deg) scale(1)',
            opacity: 0.8,
          },
          '50%': {
            transform: 'rotate(180deg) scale(1.5)',
            opacity: 0.2,
          },
          '100%': {
            transform: 'rotate(360deg) scale(1)',
            opacity: 0.8,
          }
        },
        ...sx
      }}
    />
  );

// Optional: Theme provider if you want to make colors configurable
export const arcadeTheme = {
  primary: '#ff6b00',
  shadow: 'rgba(255, 107, 0, 0.5)',
  lightShadow: 'rgba(255, 107, 0, 0.3)',
  darkShadow: 'rgba(255, 107, 0, 0.8)',
};