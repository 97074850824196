import { doc } from 'firebase/firestore';
import { useDocument } from 'react-firebase-hooks/firestore';
import { db } from './firebase';
import React, { useState } from 'react';
import { Box } from '@mui/material';
import ErrorPage from './ErrorPage';
import { ArcadeTitle, ArcadeSubtitle, ArcadeText, ArcadeDots } from './ArcadeTypography';

// Base container style to be reused
const baseContainerStyle = {
    minHeight: '100vh',
    width: '100vw',
    background: 'linear-gradient(135deg, #1a1a2e 0%, #16213e 100%)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    overflow: 'hidden',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: 'repeating-linear-gradient(45deg, transparent, transparent 10px, rgba(0,0,0,0.1) 10px, rgba(0,0,0,0.1) 20px)',
      animation: 'backgroundScroll 20s linear infinite',
      zIndex: 0,
    },
    '@keyframes backgroundScroll': {
      from: { backgroundPosition: '0 0' },
      to: { backgroundPosition: '40px 40px' }
    },
  };

const WaitingScreen = ({ message, player, showFighter }) => {
    return (
      <Box sx={baseContainerStyle}>
        {/* Show fighter info if available */}
        {showFighter && player && (
          <Box sx={{ 
            mb: 6, 
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center',
            gap: 2
          }}>
            <ArcadeText sx={{ color: '#00ffff', mb: 2 }}>
              YOUR FIGHTER:
            </ArcadeText>
            
            {player.avatar_url && (
              <Box
                sx={{
                  width: '120px',
                  height: '120px',
                  border: '4px solid #00ffff',
                  borderRadius: '8px',
                  overflow: 'hidden',
                  boxShadow: '0 0 20px rgba(0, 255, 255, 0.3)',
                  mb: 2
                }}
              >
                <img 
                  src={player.avatar_url} 
                  alt={player.fighter_name}
                  style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                />
              </Box>
            )}
            
            <ArcadeSubtitle sx={{ color: '#00ffff' }}>
              {player.fighter_name}
            </ArcadeSubtitle>
          </Box>
        )}
  
        <ArcadeTitle sx={{ color: '#ff0044' }}>
          {message}
        </ArcadeTitle>
  
        <ArcadeDots color="#ff0044" />
      </Box>
    );
  };

const VotingScreen = ({ player1, player2, allowVotes, onVotePlayer1, onVotePlayer2, selfVote }) => {
  const [selectedFighter, setSelectedFighter] = useState(null);

  const FighterCard = ({ player, onVote, isSelected }) => (
    <Box
      sx={{
        background: 'rgba(0, 0, 0, 0.8)',
        border: `4px solid ${isSelected ? '#00ffff' : 'rgba(0, 255, 255, 0.3)'}`,
        borderRadius: '8px',
        padding: '20px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '20px',
        transition: 'all 0.3s ease',
        transform: isSelected ? 'scale(1.05)' : 'scale(1)',
        boxShadow: isSelected ? '0 0 20px rgba(0, 255, 255, 0.5)' : 'none',
        position: 'relative',
        width: '100%',
        maxWidth: '400px',
        '&:hover': allowVotes && {
          border: '4px solid #00ffff',
          boxShadow: '0 0 30px rgba(0, 255, 255, 0.3)',
          cursor: 'pointer'
        }
      }}
      onClick={() => {
        if (allowVotes) {
          setSelectedFighter(player.fighter_name);
          onVote();
        }
      }}
    >
      <ArcadeSubtitle sx={{ color: '#00ffff', fontSize: '1.5rem' }}>
        {player.fighter_name}
      </ArcadeSubtitle>

      <ArcadeText sx={{ fontSize: '1rem', mb: 2 }}>
        VOTES: {player.votes}
      </ArcadeText>

      {allowVotes && (
        <Box
          sx={{
            fontFamily: '"Press Start 2P", system-ui',
            background: isSelected ? '#00ffff' : 'rgba(0, 255, 255, 0.3)',
            color: isSelected ? '#000' : '#fff',
            padding: '15px 30px',
            fontSize: '0.8rem',
            borderRadius: '4px',
            transition: 'all 0.3s ease',
          }}
        >
          VOTE
        </Box>
      )}
    </Box>
  );

  return (
    <Box sx={baseContainerStyle}>
      {selfVote ? (
        <ArcadeTitle>
          YOU ARE IN THIS MATCH!
        </ArcadeTitle>
      ) : (
        <>
          <ArcadeTitle>
            CHOOSE YOUR FIGHTER
          </ArcadeTitle>

          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              gap: '40px',
              width: '100%',
              maxWidth: '1200px',
              justifyContent: 'center',
              alignItems: 'center',
              px: 2
            }}
          >
            <FighterCard 
              player={player1}
              onVote={onVotePlayer1}
              isSelected={selectedFighter === player1.fighter_name}
            />
            
            <ArcadeSubtitle sx={{ color: '#ff0044' }}>
              VS
            </ArcadeSubtitle>
            
            <FighterCard
              player={player2}
              onVote={onVotePlayer2}
              isSelected={selectedFighter === player2.fighter_name}
            />
          </Box>
        </>
      )}
    </Box>
  );
};

const PlayerGame = ({ docId, playerId }) => {
  const docRef = doc(db, 'games', docId);
  const [value, loading, error] = useDocument(
    docRef,
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  if (loading) {
    return <WaitingScreen message="LOADING" />;
  }

  if (error) {
    return <ErrorPage errorText={JSON.stringify(error)} />;
  }

  if (value) {
    const data = value.data();
    const currentPlayer = data.players[playerId];

    if (!data.started) {
      return <WaitingScreen message="WAITING TO START" player={currentPlayer} showFighter={true} />;
    }

    if (data.voting < 0) {
      return <WaitingScreen message="WAITING TO VOTE" player={currentPlayer} showFighter={true} />;
    }

    const match = data.matches[data.voting];
    const players = data.players;
    const player1 = { ...players[match.player_id_1], votes: match.votes_1 };
    const player2 = { ...players[match.player_id_2], votes: match.votes_2 };
    const selfVote = playerId === match.player_id_1 || playerId === match.player_id_2;

    return (
      <VotingScreen
        player1={player1}
        player2={player2}
        allowVotes={!selfVote}
        onVotePlayer1={() => callVote(docId, data.voting, 1)}
        onVotePlayer2={() => callVote(docId, data.voting, 2)}
        selfVote={selfVote}
      />
    );
  }

  return null;
};

const callVote = async (docId, match, vote) => {
  try {
    const response = await fetch('https://cast-vote-http-meryawfipq-uc.a.run.app', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ docId, match, vote }),
    });
    if (!response.ok) {
      throw new Error(`Error: ${response.status} ${response.statusText}`);
    }
  } catch (error) {
    console.error("Error casting vote:", error);
  }
};

export default PlayerGame;