import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';

const CRTFrame = ({ children, width, height, isIntro = false }) => {
  const [isAnimating, setIsAnimating] = useState(isIntro);
  const [isOn, setIsOn] = useState(!isIntro);

  useEffect(() => {
    if (isIntro) {
      // Start the power-on sequence
      const timer = setTimeout(() => {
        setIsOn(true);
        setIsAnimating(false);
      }, 1500); // Total animation duration
      return () => clearTimeout(timer);
    }
  }, [isIntro]);

  return (
    <Box
      sx={{
        position: 'relative',
        width,
        height,
        mb: 8, // Space for the control panel
        opacity: isOn ? 1 : 0,
        transform: isAnimating ? 'scaleY(0.01)' : 'scaleY(1)',
        transformOrigin: 'center center',
        transition: 'transform 1s cubic-bezier(0.34, 1.56, 0.64, 1), opacity 0.1s',
      }}
    >
      {/* Base frame */}
      <Box
        sx={{
          position: 'absolute',
          top: -20,
          left: -20,
          right: -20,
          bottom: -20,
          background: 'linear-gradient(180deg, #272727 0%, #323232 100%)',
          borderRadius: '20px',
          boxShadow: `
            inset 0 0 20px 0 rgba(0, 0, 0, 0.8),
            0 0 30px rgba(0, 255, 255, 0.5),
            0 5px 15px rgba(0, 0, 0, 0.5)
          `,
          zIndex: 1,
        }}
      />

      {/* Content container with CRT power-on effect */}
      <Box
        sx={{
          position: 'relative',
          width: '100%',
          height: '100%',
          borderRadius: '10px',
          overflow: 'hidden',
          zIndex: 2,
          '&::before': isOn ? {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: 'white',
            opacity: 1,
            animation: 'crtOn 1.5s ease-out forwards',
            pointerEvents: 'none',
            zIndex: 3,
          } : {},
          '@keyframes crtOn': {
            '0%': {
              opacity: 1,
              transform: 'scaleY(0.01)',
            },
            '20%': {
              opacity: 1,
              transform: 'scaleY(1)',
            },
            '30%': {
              opacity: 1,
              transform: 'scaleY(1)',
            },
            '100%': {
              opacity: 0,
              transform: 'scaleY(1)',
            }
          },
        }}
      >
        {children}
      </Box>

      {/* Screen overlay effects */}
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: `
            linear-gradient(
              rgba(18, 16, 16, 0) 50%,
              rgba(0, 0, 0, 0.25) 50%
            ),
            linear-gradient(
              90deg,
              rgba(255, 0, 0, 0.06),
              rgba(0, 255, 0, 0.02),
              rgba(0, 0, 255, 0.06)
            )
          `,
          backgroundSize: '100% 2px, 3px 100%',
          pointerEvents: 'none',
          zIndex: 3,
          opacity: isOn ? 1 : 0,
          transition: 'opacity 0.3s',
          animation: isOn ? 'flicker 0.15s infinite' : 'none',
          '@keyframes flicker': {
            '0%': { opacity: 0.97 },
            '50%': { opacity: 1 },
            '100%': { opacity: 0.98 }
          }
        }}
      />

      {/* Logo plate */}
      <Box
        sx={{
          position: 'absolute',
          top: -10,
          right: 20,
          background: '#1a1a1a',
          padding: '2px 8px',
          borderRadius: '2px',
          transform: 'translateY(-50%)',
          zIndex: 4,
          fontSize: '8px',
          fontFamily: '"Press Start 2P", system-ui',
          color: '#666',
          letterSpacing: '1px',
          boxShadow: 'inset 0 0 2px rgba(0,0,0,0.8)',
          border: '1px solid #333',
        }}
      >
        CHOOSE YOUR FIGHTER
      </Box>

      {/* Control panel */}
      <Box
        sx={{
          position: 'absolute',
          bottom: -60,
          left: '50%',
          transform: 'translateX(-50%)',
          width: '80%',
          height: '40px',
          background: 'linear-gradient(180deg, #323232 0%, #272727 100%)',
          borderRadius: '0 0 10px 10px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '20px',
          padding: '0 20px',
          zIndex: 4,
          boxShadow: 'inset 0 2px 5px rgba(0,0,0,0.3)',
        }}
      >
        {/* Power button */}
        <Box
          sx={{
            width: '16px',
            height: '16px',
            borderRadius: '50%',
            background: isOn 
              ? 'radial-gradient(circle at 30% 30%, #ff6b00, #ff0000)'
              : '#440000',
            boxShadow: isOn
              ? 'inset 0 0 2px 2px rgba(0,0,0,0.3), 0 0 5px rgba(255,0,0,0.5)'
              : 'inset 0 0 2px 2px rgba(0,0,0,0.3)',
            border: '2px solid #1a1a1a',
            transition: 'all 0.3s',
          }}
        />
        
        {/* Cartridge slot */}
        <Box
          sx={{
            width: '100px',
            height: '8px',
            background: '#1a1a1a',
            borderRadius: '4px',
            boxShadow: 'inset 0 0 5px rgba(0,0,0,0.8)',
            border: '1px solid #444',
          }}
        />

        {/* Decorative LEDs */}
        <Box sx={{ display: 'flex', gap: '8px' }}>
          {['#00ff00', '#ffff00', '#00ffff'].map((color, i) => (
            <Box
              key={i}
              sx={{
                width: '6px',
                height: '6px',
                borderRadius: '50%',
                background: isOn
                  ? `radial-gradient(circle at 30% 30%, ${color}, ${color}88)`
                  : '#333',
                boxShadow: isOn ? `0 0 3px ${color}` : 'none',
                animation: isOn ? 'blink 2s infinite' : 'none',
                animationDelay: `${i * 0.7}s`,
                transition: 'all 0.3s',
                '@keyframes blink': {
                  '0%, 100%': { opacity: 1 },
                  '50%': { opacity: 0.3 },
                },
              }}
            />
          ))}
        </Box>

        {/* Volume lines */}
        <Box
          sx={{
            display: 'flex',
            gap: '3px',
            marginLeft: 'auto',
          }}
        >
          {[...Array(4)].map((_, i) => (
            <Box
              key={i}
              sx={{
                width: '3px',
                height: `${8 + i * 3}px`,
                background: '#444',
                borderRadius: '1px',
              }}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default CRTFrame;