import React from 'react';
import Christmas from './Christmas';

const WINE_EMOJIS = ['🍷', '🍇', '🥂', '🍾', '🧀', '🥖', '🫒', '🍓', '🍊', '🍐'];

const CORNER_DECORATIONS = [
  '🍷 🍇 🥂', // top-left
  '🍾 🧀 🥖', // top-right
  '🎁 🎄 🫒', // bottom-left
  '⭐ 🌟 🍊'  // bottom-right
];

const VIDEO_URL = "https://storage.googleapis.com/choose-your-fighter-8/Gen-3%20Alpha%20Turbo%203557964828%2C%20The%20two%20people%20pick%20%2C%20Cropped%20-%20IMG_202412%2C%20M%205.mp4";
const CERTIFICATE_URL = "https://storage.googleapis.com/choose-your-fighter-8/Screenshot%202024-12-14%20143027.png";
const BOOKING_URL = "https://www.vinology.com/?sc-page=shop&coupon-code=gcvm1mz7w&utm_campaign=website&utm_medium=email&utm_source=sendgrid.com";
const COUPON_CODE = "GCvm1mz7w";

export default function ChristmasMomDad() {
  const handleBooking = () => {
    window.open(BOOKING_URL, '_blank');
  };

  return (
    <Christmas
      title="Mom and Dad"
      videoUrl={VIDEO_URL}
      certificateUrls={[CERTIFICATE_URL]}
      themeEmojis={WINE_EMOJIS}
      onBooking={handleBooking}
      cornerEmojis={CORNER_DECORATIONS}
      couponCode={COUPON_CODE}
    />
  );
}