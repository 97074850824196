import React from 'react';
import { Box } from '@mui/material';
import { useDocument } from 'react-firebase-hooks/firestore';
import ErrorPage from './ErrorPage';
import { Swords } from 'lucide-react';
import { ArcadeTitle, ArcadeDots } from './ArcadeTypography';

const MatchBox = ({ match, players, isActive }) => {
    const isComplete = match.winner !== -1;
  
    const PlayerDisplay = ({ playerId, isWinner }) => {
      const isLoser = isComplete && !isWinner;
      return (
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          gap: 2,
          position: 'relative'
        }}>
          <Box 
            sx={{
              width: '32px',
              height: '32px',
              borderRadius: '4px',
              overflow: 'hidden',
              border: '1px solid',
              borderColor: isWinner ? '#00ff00' : isLoser ? '#ff0044' : 'rgba(0, 255, 255, 0.3)',
              position: 'relative'
            }}
          >
            <img 
              src={players[playerId]?.avatar_url || "/api/placeholder/32/32"}
              alt=""
              style={{ 
                width: '100%', 
                height: '100%', 
                objectFit: 'cover',
                opacity: isLoser ? 0.5 : 1
              }}
            />
          </Box>
          <Box sx={{
            color: isWinner ? '#00ff00' : isLoser ? '#ff0044' : '#fff',
            fontFamily: '"Press Start 2P", system-ui',
            fontSize: '0.8rem',
            position: 'relative',
            // Cross-out effect for losers
            ...(isLoser && {
              '&::after': {
                content: '""',
                position: 'absolute',
                left: '-5%',
                top: '50%',
                width: '110%',
                height: '2px',
                background: '#ff0044',
                transform: 'rotate(-5deg)',
                boxShadow: '0 0 5px rgba(255, 0, 68, 0.5)'
              }
            })
          }}>
            {players[playerId]?.fighter_name || 'TBD'}
          </Box>
        </Box>
      );
    };
  
    return (
      <Box sx={{
        width: '240px',
        padding: '12px 20px',
        border: '2px solid rgba(0, 255, 255, 0.3)',
        borderRadius: '4px',
        background: 'rgba(0, 0, 0, 0.3)',
        display: 'flex',
        flexDirection: 'column',
        gap: '8px'
      }}>
        <PlayerDisplay 
          playerId={match.player_id_1} 
          isWinner={match.winner === match.player_id_1}
        />
  
        {/* VS or Swords */}
        <Box sx={{ 
          textAlign: 'center', 
          color: isActive ? '#ff0044' : '#ff6b00',
          fontFamily: '"Press Start 2P", system-ui',
          fontSize: '0.8rem',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
          {isActive ? (
            <Swords 
              size={24} 
              strokeWidth={1.5}
              style={{
                animation: 'swordsCross 1s infinite',
                transformOrigin: 'center'
              }}
            />
          ) : (
            'VS'
          )}
        </Box>
  
        <PlayerDisplay 
          playerId={match.player_id_2}
          isWinner={match.winner === match.player_id_2}
        />
  
        {/* Add CSS for swords animation */}
        <style>
          {`
            @keyframes swordsCross {
              0%, 100% { transform: scale(1) rotate(0deg); }
              50% { transform: scale(1.2) rotate(5deg); }
            }
          `}
        </style>
      </Box>
    );
  };

const BracketScreen = ({ docRef, matchesReady, activeMatchIdx, roundEnd }) => {
  const [value, loading, error] = useDocument(
    docRef,
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  if (loading || !matchesReady) {
    return (
      <Box sx={{ 
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
        <ArcadeTitle color="#00ffff">
          Simulating Tournament
        </ArcadeTitle>
        <ArcadeDots color="#00ffff" />
      </Box>
    );
  }

  if (error) {
    return <ErrorPage errorText={JSON.stringify(error)}/>;
  }

  if (!value) {
    return <ErrorPage errorText="No tournament data found" />;
  }

  const data = value.data();
  const matches = data.matches;
  const players = data.players;

  // Group matches into rounds
  const rounds = [];
  let currentRoundSize = Math.floor(players.length / 2);
  let currentRound = [];
  let matchIndex = 0;

  while (matchIndex < matches.length) {
    currentRound.push(matches[matchIndex]);
    matchIndex++;

    if (currentRound.length === currentRoundSize) {
      rounds.push(currentRound);
      currentRound = [];
      currentRoundSize = Math.max(1, Math.floor(currentRoundSize / 2));
    }
  }

  return (
    <Box sx={{ 
      height: '100vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
      <Box sx={{
        display: 'flex',
        gap: '60px',
        height: '80vh'  // Take up most of the viewport height
      }}>
        {rounds.map((roundMatches, roundIndex) => {
          // Calculate the vertical offset for each match in this round
          const totalHeight = '80vh';
          const matchCount = roundMatches.length;
          const spacing = `calc(${totalHeight} / ${matchCount + 1})`; // Even spacing between matches
          
          return (
            <Box 
              key={roundIndex}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-around',
                height: '100%',
                position: 'relative'
              }}
            >
              {roundMatches.map((match, matchIndex) => {
                let globalMatchIdx = 0;
                for (let i = 0; i < roundIndex; i++) {
                  globalMatchIdx += rounds[i].length;
                }
                globalMatchIdx += matchIndex;

                return (
                  <Box 
                    key={matchIndex} 
                    sx={{
                      // Center the match vertically within its space
                      my: 'auto'
                    }}
                  >
                    <MatchBox 
                      match={match}
                      players={players}
                      isActive={globalMatchIdx === activeMatchIdx}
                    />
                  </Box>
                );
              })}
            </Box>
          );
        })}
      </Box>

      {roundEnd && (
        <ArcadeTitle 
          color="#00ffff"
          sx={{ 
            position: 'fixed',
            bottom: 32,
            left: 0,
            right: 0,
            textAlign: 'center'
          }}
        >
          Round Complete! Next Round Starting Soon...
        </ArcadeTitle>
      )}
    </Box>
  );
};

export default BracketScreen;