import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, CircularProgress } from '@mui/material';
import ErrorPage from './ErrorPage';
import PlayerGame from './PlayerGame';
import { 
  ArcadeTitle, 
  ArcadeSubtitle, 
  ArcadeText 
} from './ArcadeTypography';

const inputStyles = {
    width: '100%',
    marginBottom: 2,
    padding: '18px',
    background: 'rgba(255, 255, 255, 0.1)',
    border: '2px solid rgba(255, 255, 255, 0.2)',
    borderRadius: '4px',
    color: '#fff',
    fontSize: '1rem',
    fontFamily: '"Press Start 2P", system-ui',
    transition: 'all 0.3s ease',
    outline: 'none',
    '&:focus': {
        border: '2px solid #ff0044',
        boxShadow: '0 0 10px rgba(255, 0, 68, 0.3)',
    },
    '&::placeholder': {
        color: 'rgba(255, 255, 255, 0.5)',
        fontFamily: '"Press Start 2P", system-ui',
        fontSize: '0.8rem',
    },
};

const redButtonStyles = {
    width: '100%',
    padding: '20px',
    border: 'none',
    borderRadius: '4px',
    color: '#fff',
    fontSize: '1.2rem',
    fontFamily: '"Press Start 2P", system-ui',
    fontWeight: 'bold',
    textAlign: 'center',
    textTransform: 'uppercase',
    cursor: 'pointer',
    transition: 'all 0.3s ease',
    background: 'linear-gradient(45deg, #ff0044 30%, #ff4444 90%)',
    border: '2px solid #ff0044',
    boxShadow: '0 0 15px rgba(255, 0, 68, 0.5)',
    '&:hover': {
        transform: 'translateY(-2px)',
        boxShadow: '0 0 20px rgba(255, 0, 68, 0.7)',
        background: 'linear-gradient(45deg, #ff4444 30%, #ff6666 90%)',
    },
    '&:active': {
        transform: 'translateY(1px)',
    },
    '&:disabled': {
        background: '#333',
        border: '2px solid #666',
        color: '#666',
        boxShadow: 'none',
        cursor: 'not-allowed',
    }
};

const blueButtonStyles = {
    ...redButtonStyles,
    background: 'linear-gradient(45deg, #00ffff 30%, #44ffff 90%)',
    border: '2px solid #00ffff',
    boxShadow: '0 0 15px rgba(0, 255, 255, 0.5)',
    color: '#000',
    '&:hover': {
        transform: 'translateY(-2px)',
        boxShadow: '0 0 20px rgba(0, 255, 255, 0.7)',
        background: 'linear-gradient(45deg, #44ffff 30%, #66ffff 90%)',
    },
};

const ArcadeEntry = () => {
    const navigate = useNavigate();
    const [disableButtons, setDisableButtons] = useState(false);
    const [lobbyCodeFieldValue, setLobbyCodeFieldValue] = useState('');
    const [fighterNameFieldValue, setFighterNameFieldValue] = useState('');
    const [fighterDescriptionFieldValue, setFighterDescriptionFieldValue] = useState('');
    const [errorText, setErrorText] = useState('');
    const [docId, setDocId] = useState('');
    const [playerId, setPlayerId] = useState(-1);

    const callJoinGame = async () => {
        setDisableButtons(true);
        try {
            const response = await fetch('https://join-game-http-meryawfipq-uc.a.run.app', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    lobbyCode: lobbyCodeFieldValue.toUpperCase(),
                    fighterName: fighterNameFieldValue,
                    fighterDescription: fighterDescriptionFieldValue
                }),
            });
            if (!response.ok) {
                throw new Error(response.status === 404 ? 'Lobby not found!' : 'Connection failed!');
            }
            const data = await response.json();
            setDocId(data.docId);
            setPlayerId(data.playerId);
        } catch (error) {
            setErrorText(error.message);
            setDisableButtons(false);
        }
    };

    if (docId !== '') {
        return <PlayerGame docId={docId} playerId={playerId} />;
    }

    return (
        <Box sx={{
            minHeight: '100vh',
            background: 'linear-gradient(135deg, #1a1a2e 0%, #16213e 100%)',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            position: 'relative',
            overflow: 'hidden',
            fontFamily: '"Press Start 2P", system-ui',
            '&::before': {
                content: '""',
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                background: 'repeating-linear-gradient(45deg, transparent, transparent 10px, rgba(0,0,0,0.1) 10px, rgba(0,0,0,0.1) 20px)',
                animation: 'backgroundScroll 20s linear infinite',
            },
            '@keyframes backgroundScroll': {
                from: { backgroundPosition: '0 0' },
                to: { backgroundPosition: '40px 40px' }
            },
        }}>
            <ArcadeTitle sx={{ my: 4 }}>
                Choose Your Fighter
            </ArcadeTitle>

            <Box sx={{
                display: 'flex',
                gap: 4,
                maxWidth: '1200px',
                width: '90%',
                flexWrap: { xs: 'wrap', md: 'nowrap' },
                position: 'relative',
                '&::before': {
                    content: '""',
                    position: 'absolute',
                    inset: -2,
                    background: 'linear-gradient(45deg, #ff0044, #00ffff)',
                    borderRadius: 2,
                    padding: 2,
                    zIndex: 0,
                },
            }}>
                {/* Join Battle Section */}
                <Box sx={{
                    flex: '2',
                    background: 'rgba(0, 0, 0, 0.8)',
                    padding: 4,
                    borderRadius: 2,
                    zIndex: 1,
                }}>
                    <ArcadeSubtitle 
                        sx={{ 
                            mb: 3,
                            color: '#ff0044',
                            textShadow: `
                                2px 2px 0 #000,
                                -2px -2px 0 #000,
                                2px -2px 0 #000,
                                -2px 2px 0 #000,
                                0 0 8px rgba(255, 0, 68, 0.8),
                                0 0 16px rgba(255, 0, 68, 0.4)
                            `,
                        }}
                    >
                        Join Battle
                    </ArcadeSubtitle>

                    <Box component="input"
                        placeholder="Lobby Code"
                        value={lobbyCodeFieldValue}
                        onChange={(e) => setLobbyCodeFieldValue(e.target.value)}
                        sx={inputStyles}
                    />

                    <Box component="input"
                        placeholder="Fighter Name"
                        value={fighterNameFieldValue}
                        onChange={(e) => setFighterNameFieldValue(e.target.value)}
                        sx={inputStyles}
                    />

                    <Box component="textarea"
                        placeholder="Fighter Description"
                        value={fighterDescriptionFieldValue}
                        onChange={(e) => setFighterDescriptionFieldValue(e.target.value)}
                        rows={4}
                        sx={{
                            ...inputStyles,
                            resize: 'none',
                            minHeight: '100px',
                        }}
                    />

                    <Box 
                        onClick={!disableButtons ? callJoinGame : undefined}
                        sx={{
                            ...redButtonStyles,
                            opacity: disableButtons ? 0.7 : 1,
                            cursor: disableButtons ? 'not-allowed' : 'pointer',
                        }}
                    >
                        {disableButtons ? (
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, justifyContent: 'center' }}>
                                <CircularProgress size={20} sx={{ color: '#fff' }} />
                                <ArcadeText sx={{ 
                                    color: '#fff', 
                                    fontSize: '0.8rem',
                                    textShadow: 'none'
                                }}>
                                    Connecting...
                                </ArcadeText>
                            </Box>
                        ) : 'JOIN BATTLE'}
                    </Box>
                </Box>

                {/* Host Arena Section */}
                <Box sx={{
                    flex: '1',
                    background: 'rgba(0, 0, 0, 0.8)',
                    padding: 4,
                    borderRadius: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: 1,
                }}>
                    <ArcadeSubtitle
                        sx={{ 
                            color: '#00ffff',
                            textShadow: `
                                2px 2px 0 #000,
                                -2px -2px 0 #000,
                                2px -2px 0 #000,
                                -2px 2px 0 #000,
                                0 0 8px rgba(0, 255, 255, 0.8),
                                0 0 16px rgba(0, 255, 255, 0.4)
                            `,
                        }}
                    >
                        Host Arena
                    </ArcadeSubtitle>

                    <ArcadeText sx={{
                        fontSize: '0.8rem',
                        opacity: 0.8,
                        my: 3,
                        lineHeight: 1.8,
                        color: '#00ffff',
                        textShadow: '0 0 10px rgba(0, 255, 255, 0.3)',
                    }}>
                        Create your own battle arena and invite fighters to join
                    </ArcadeText>

                    <Box 
                        onClick={() => navigate('/host')}
                        sx={blueButtonStyles}
                    >
                        START ARENA
                    </Box>
                </Box>
            </Box>

            <ErrorPage errorText={errorText} />
        </Box>
    );
};

export default ArcadeEntry;