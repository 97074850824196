import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Box, Button } from '@mui/material';

import { useDocument } from 'react-firebase-hooks/firestore';
import { doc } from 'firebase/firestore';
import { db } from './firebase';
import { initDevFirestore, mockDocIds, mockPlayers } from './DevFirestore';
import ErrorPage from "./ErrorPage";
import { ArcadeTitle, ArcadeDots } from './ArcadeTypography';


// Components
import BracketScreen from './BracketScreen';
import HostLobby from './HostLobby';
import MatchScreen from './MatchScreen';
import PlayerGame from './PlayerGame';

// Background container for all dev screens
const DevContainer = ({ children }) => {
  useEffect(() => {
    initDevFirestore();
  }, []);

  return (
    <Box sx={{
      minHeight: '100vh',
      background: 'linear-gradient(135deg, #1a1a2e 0%, #16213e 100%)',
      position: 'relative',
      '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: 'repeating-linear-gradient(45deg, transparent, transparent 10px, rgba(0,0,0,0.1) 10px, rgba(0,0,0,0.1) 20px)',
        animation: 'backgroundScroll 20s linear infinite',
        zIndex: 0,
      },
      '@keyframes backgroundScroll': {
        from: { backgroundPosition: '0 0' },
        to: { backgroundPosition: '40px 40px' }
      },
    }}>
      <Box sx={{ position: 'relative', zIndex: 1 }}>
        {children}
      </Box>
    </Box>
  );
};

// Development menu to access all screens
const DevMenu = () => (
  <DevContainer>
    <Box sx={{
      padding: 4,
      display: 'flex',
      flexDirection: 'column',
      gap: 2,
    }}>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        maxWidth: '600px',
        margin: '0 auto',
        width: '100%',
      }}>
        {[
          { path: '/dev/bracket', name: 'Bracket Screen' },
          { path: '/dev/bracket-loading', name: 'Bracket Loading' },
          { path: '/dev/bracket-round-end', name: 'Bracket Round End' },
          { path: '/dev/host-lobby', name: 'Host Lobby' },
          { path: '/dev/match', name: 'Match Screen' },
          { path: '/dev/player-waiting', name: 'Player Waiting' },
          { path: '/dev/player-voting', name: 'Player Voting' },
          { path: '/dev/player-in-match', name: 'Player In Match' },
        ].map(({ path, name }) => (
          <Button
            key={path}
            component={Link}
            to={path}
            variant="contained"
            sx={{
              background: 'linear-gradient(45deg, #00ffff 30%, #44ffff 90%)',
              color: '#000',
              fontFamily: '"Press Start 2P", system-ui',
              fontSize: '0.8rem',
              padding: '16px',
              '&:hover': {
                background: 'linear-gradient(45deg, #00cccc 30%, #33cccc 90%)',
              },
            }}
          >
            {name}
          </Button>
        ))}
      </Box>
    </Box>
  </DevContainer>
);

const DevMatchScreen = () => {
    const [value, loading, error] = useDocument(
      doc(db, 'games', mockDocIds.matchGame),
      {
        snapshotListenOptions: { includeMetadataChanges: true },
      }
    );
  
    if (loading) {
      return (
        <DevContainer>
          <Box sx={{ 
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
            <ArcadeTitle color="#00ffff">
              Loading Match...
            </ArcadeTitle>
            <ArcadeDots color="#00ffff" />
          </Box>
        </DevContainer>
      );
    }
  
    if (error) {
      return (
        <DevContainer>
          <ErrorPage errorText={error.message} />
        </DevContainer>
      );
    }
  
    if (!value || !value.exists()) {
      return (
        <DevContainer>
          <ErrorPage errorText="Match data not found" />
        </DevContainer>
      );
    }
  
    return (
      <DevContainer>
        <MatchScreen 
          docId={mockDocIds.matchGame}
          docValue={value}
          activeMatchIndex={0}
          doneCallback={() => console.log('Match done')}
        />
      </DevContainer>
    );
  };

// Development routes configuration
export const devRoutes = [
  <Route key="dev" path="/dev" element={<DevMenu />} />,
  
  // Bracket screen states
  <Route 
    key="bracket" 
    path="/dev/bracket" 
    element={
      <DevContainer>
        <BracketScreen 
          docRef={doc(db, 'games', mockDocIds.bracketGame)}
          matchesReady={true}
          activeMatchIdx={2}
          roundEnd={false}
        />
      </DevContainer>
    }
  />,
  
  <Route 
    key="bracket-loading" 
    path="/dev/bracket-loading" 
    element={
      <DevContainer>
        <BracketScreen 
          docRef={doc(db, 'games', mockDocIds.bracketGame)}
          matchesReady={false}
          activeMatchIdx={-1}
          roundEnd={false}
        />
      </DevContainer>
    }
  />,
  
  <Route 
    key="bracket-round-end" 
    path="/dev/bracket-round-end" 
    element={
      <DevContainer>
        <BracketScreen 
          docRef={doc(db, 'games', mockDocIds.bracketGame)}
          matchesReady={true}
          activeMatchIdx={-1}
          roundEnd={true}
        />
      </DevContainer>
    }
  />,

  // Host screens
  <Route 
    key="host-lobby" 
    path="/dev/host-lobby" 
    element={
      <DevContainer>
        <HostLobby 
          docId={mockDocIds.lobbyGame}
          lobbyCode="TEST1"
          playerNames={mockPlayers.map(p => p.fighter_name)}
          startHandler={() => console.log('Start clicked')}
          disableButtons={false}
        />
      </DevContainer>
    }
  />,

<Route 
  key="match" 
  path="/dev/match" 
  element={<DevMatchScreen />}
/>,

  // Player screens
  <Route 
    key="player-waiting" 
    path="/dev/player-waiting" 
    element={
      <DevContainer>
        <PlayerGame 
          docId={mockDocIds.waitingGame}
          playerId={0}
        />
      </DevContainer>
    }
  />,
  
  <Route 
    key="player-voting" 
    path="/dev/player-voting" 
    element={
      <DevContainer>
        <PlayerGame 
          docId={mockDocIds.votingGame}
          playerId={2}
        />
      </DevContainer>
    }
  />,

  <Route 
    key="player-in-match" 
    path="/dev/player-in-match" 
    element={
      <DevContainer>
        <PlayerGame 
          docId={mockDocIds.votingGame}
          playerId={0} // Player 0 is in the active match
        />
      </DevContainer>
    }
  />,
];