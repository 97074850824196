import AudioPlayerComponent from "./AudioPlayerComponent";
import { useState, useEffect } from "react";
import { Box, Typography } from '@mui/material';
import VotingScreen from "./VotingScreen";
import ErrorPage from "./ErrorPage";
import LoadingIndicator from "./LoadingIndicator";
import Timer from "./Timer";
import CRTFrame from "./CRTFrame";

const MatchScreen = ({docId, docValue, activeMatchIndex, doneCallback}) => {
    const [errorText, setErrorText] = useState('');
    const [imageLoaded, setImageLoaded] = useState(false); // State to track if the image has loaded

    const matches = docValue.data().matches;
    const players = docValue.data().players;
    const match = matches[activeMatchIndex];

    const player1 = players[match.player_id_1];
    player1.votes = match.votes_1;
    const player2 = players[match.player_id_2];
    player2.votes = match.votes_2;

    const [phaseIdx, setPhaseIdx] = useState(0);
    const voteAfterPhase = 4;

    const [voting, setVoting] = useState(false);

    const phases = [
        {
            txt: match.initial_action_text_1,
            img: match.initial_action_image_1,
            tts: match.initial_action_tts_1
        },
        {
            txt: match.initial_action_text_2,
            img: match.initial_action_image_2,
            tts: match.initial_action_tts_2
        },
        {
            txt: match.initial_action_resolution_text,
            img: match.initial_action_resolution_image,
            tts: match.initial_action_resolution_tts
        },
        {
            txt: match.final_action_text_1,
            img: match.final_action_image_1,
            tts: match.final_action_tts_1
        },
        {
            txt: match.final_action_text_2,
            img: match.final_action_image_2,
            tts: match.final_action_tts_2
        },
        {
            txt: match.final_action_resolution_text_1,
            img: match.final_action_resolution_image_1,
            tts: match.final_action_resolution_tts_1
        },
        {
            txt: match.final_action_resolution_text_2,
            img: match.final_action_resolution_image_2,
            tts: match.final_action_resolution_tts_2
        },
    ]

    const audioDoneCallback = () => {
        console.log("audio done");
        if (phaseIdx === voteAfterPhase) {
            setVoting(true);
        } else if (phaseIdx >= voteAfterPhase) {
            doneCallback();
        } else {
            if (phases[phaseIdx].img !== phases[phaseIdx+1].img) {
                setImageLoaded(false);
            }
            setPhaseIdx(phaseIdx + 1);
        }
    }

    useEffect(() => {
        const callStartVote = async () => {
            try {
                const response = await fetch('https://start-vote-http-meryawfipq-uc.a.run.app', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        docId: docId,
                        match: activeMatchIndex,
                    }),
                });
                if (!response.ok) {
                    const data = await response.json();
                    throw new Error(`Error: ${response.status} ${response.statusText} ${JSON.stringify(data)}`);
                }
            } catch (error) {
                console.error("Error:", error);
                setErrorText(error.message);
            }
        }

        if (voting) {
            callStartVote();
        }
    }, [voting, activeMatchIndex, docId]);

    const votingDoneCallback = async () => {
        const callSetWinner = async () => {
            try {
                const response = await fetch('https://set-winner-http-meryawfipq-uc.a.run.app', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        docId: docId,
                        match: activeMatchIndex,
                    }),
                });
                if (!response.ok) {
                    const data = await response.json();
                    throw new Error(`Error: ${response.status} ${response.statusText} ${JSON.stringify(data)}`);
                }
                const data = await response.json();
                return data.winner;
            } catch (error) {
                console.error("Error:", error);
                setErrorText(error.message);
            }
        }

        const winner = await callSetWinner();
        
        if (winner === match.player_id_1) {
            setPhaseIdx(phaseIdx + 1);
        } else {
            setPhaseIdx(phaseIdx + 2);
        }
        setVoting(false);
    };

    if (voting) {
        return (
            <div>
                <Box sx={{ height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 4 }}>
                    <VotingScreen player1={player1} player2={player2} allowVotes={false} />
                    <Timer duration={20} callback={votingDoneCallback} />
                    <ErrorPage errorText={errorText}/>
                </Box>
            </div>
        );
    }
    return (
        <Box sx={{ 
            minHeight: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }}>
            <CRTFrame 
                width="80vw" 
                height="80vh"
                isIntro={true}
            >
                <Box sx={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    background: 'linear-gradient(180deg, #1a1a2e 0%, #16213e 100%)',
                }}>
                    {!imageLoaded &&
                        <LoadingIndicator />
                    }
                    <AudioPlayerComponent filePath={phases[phaseIdx].tts} doneCallback={audioDoneCallback} />
                    {imageLoaded && (
                        <>
                            <img
                                src={phases[phaseIdx].img}
                                alt="Battle Scene"
                                onLoad={() => setImageLoaded(true)}
                                onError={() => setImageLoaded(true)}
                                style={{ 
                                    maxWidth: '100%',
                                    maxHeight: '70%',
                                    objectFit: 'contain'
                                }}
                            />
                            <Typography 
                                variant="h4" 
                                sx={{ 
                                    textAlign: 'center', 
                                    maxWidth: '90%',
                                    mt: 2,
                                    fontFamily: '"Press Start 2P", system-ui',
                                    fontSize: '1.5rem',
                                    color: '#fff',
                                    textShadow: '0 0 10px rgba(0, 255, 255, 0.5)'
                                }}
                            >
                                {phases[phaseIdx].txt}
                            </Typography>
                        </>
                    )}
                </Box>
            </CRTFrame>
            <ErrorPage errorText={errorText}/>
        </Box>
    );
};

export default MatchScreen;