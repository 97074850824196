import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { HelpCircle, Loader } from 'lucide-react';
import { ArcadeTitle, ArcadeText } from './ArcadeTypography';
import { doc } from 'firebase/firestore';
import { useDocument } from 'react-firebase-hooks/firestore';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import { db } from './firebase';

const GRID_ROWS = 2;
const GRID_COLS = 4;
const CYCLE_INTERVAL = 5000; // 2 seconds per character

// Create a simple colored rectangle as placeholder
const createPlaceholderDataUrl = (width, height, color = '#666666') => {
  const canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;
  const ctx = canvas.getContext('2d');
  ctx.fillStyle = color;
  ctx.fillRect(0, 0, width, height);
  return canvas.toDataURL();
};

const DEFAULT_AVATAR_PLACEHOLDER = createPlaceholderDataUrl(96, 96);
const DEFAULT_ILLUSTRATION_PLACEHOLDER = createPlaceholderDataUrl(800, 600, '#444444');


const CharacterSlot = ({ player, isSelected, isLoading }) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const [imageError, setImageError] = useState(false);

  // Reset states when player changes
  useEffect(() => {
    setImageLoaded(false);
    setImageError(false);
  }, [player?.avatar_url]);

  const showLoadingState = isLoading || (player?.avatar_url && !imageLoaded && !imageError);
  const showPlaceholder = !player || imageError || (!player.avatar_url);

  return (
    <Box
      sx={{
        width: '72px',
        height: '72px',
        borderTop: '3px solid',
        borderLeft: '3px solid',
        borderColor: 'rgba(0, 255, 255, 0.3)',
        background: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        transition: 'all 0.3s ease',
        overflow: 'hidden',
        outline: isSelected ? '3px solid #ff0044' : 'none',
        outlineOffset: '-3px',
        zIndex: isSelected ? 2 : 1,
      }}
    >
      {showLoadingState && (
        <Loader className="animate-spin" size={24} color="#00ffff" />
      )}
      
      {showPlaceholder && !showLoadingState && (
        <HelpCircle size={24} color="#666" />
      )}
      
      {player?.avatar_url && (
        <img 
          src={player.avatar_url}
          alt={player.fighter_name}
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            display: imageLoaded ? 'block' : 'none',
          }}
          onLoad={() => setImageLoaded(true)}
          onError={() => setImageError(true)}
        />
      )}
    </Box>
  );
};

const CharacterInfo = ({ player }) => (
  <Box
    sx={{
      background: 'rgba(0, 0, 0, 0.8)',
      border: '2px solid #00ffff',
      borderRadius: '8px',
      padding: '12px',
      maxWidth: '600px',
      width: '90%',
      boxShadow: '0 0 20px rgba(0, 255, 255, 0.3)',
    }}
  >
    <ArcadeTitle sx={{ 
      color: '#00ffff', 
      fontSize: '1.5rem', 
      mb: 1,
      lineHeight: 1.2
    }}>
      {player.fighter_name}
    </ArcadeTitle>
    <ArcadeText sx={{ 
      color: '#fff', 
      fontSize: '0.8rem', 
      lineHeight: 1.4,
      maxHeight: '60px',
      overflow: 'auto'
    }}>
      {player.fighter_description}
    </ArcadeText>
  </Box>
);

const IllustrationDisplay = ({ player }) => {
  const [imageUrl, setImageUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (!player?.illustration_url) {
      setIsLoading(false);
      setError(true);
      return;
    }

    setIsLoading(true);
    setError(false);

    // Check if it's already a full URL (for development/testing)
    if (player.illustration_url.startsWith('http')) {
      setImageUrl(player.illustration_url);
      setIsLoading(false);
      return;
    }

    // Otherwise, try to fetch from Firebase Storage
    const storage = getStorage();
    const imageRef = ref(storage, player.illustration_url);

    getDownloadURL(imageRef)
      .then(url => {
        setImageUrl(url);
        setIsLoading(false);
      })
      .catch(error => {
        console.error("Error getting illustration URL:", error);
        // Fall back to a placeholder
        setImageUrl(`https://placehold.co/800x600/444444/FFFFFF/png?text=${encodeURIComponent(player.fighter_name)}`);
        setIsLoading(false);
      });
  }, [player?.illustration_url, player?.fighter_name]);

  return (
    <Box sx={{
      width: '100%',
      maxWidth: '600px',
      height: '300px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      mb: 1,
    }}>
      {isLoading ? (
        <Loader className="animate-spin" size={48} color="#00ffff" />
      ) : !imageUrl ? (
        <Box sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          background: 'rgba(0, 0, 0, 0.3)',
        }}>
          <HelpCircle size={48} color="#666" />
        </Box>
      ) : (
        <img
          src={imageUrl}
          alt={player.fighter_name}
          style={{
            maxWidth: '100%',
            maxHeight: '100%',
            objectFit: 'contain',
          }}
        />
      )}
    </Box>
  );
};

const HostLobby = ({ docId, lobbyCode, playerNames = [], startHandler, disableButtons }) => {
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [loadingPlayers, setLoadingPlayers] = useState([]);
  const [players, setPlayers] = useState(Array(GRID_ROWS * GRID_COLS).fill(null));
  const [value] = useDocument(
    docId ? doc(db, 'games', docId) : null,
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  // Update players when document changes
  useEffect(() => {
    if (!value?.data()?.players) return;
    
    const gamePlayers = value.data().players;
    setPlayers(prev => {
      const newPlayers = [...prev];
      gamePlayers.forEach((player, idx) => {
        if (idx < GRID_ROWS * GRID_COLS) {
          newPlayers[idx] = player;
        }
      });
      return newPlayers;
    });
  }, [value]);

  // Handle loading states for new players
  useEffect(() => {
    if (!value?.data()?.players) return;

    const gamePlayers = value.data().players;
    playerNames.forEach(name => {
      const playerIndex = gamePlayers.findIndex(p => p.fighter_name === name);
      if (playerIndex !== -1 && !loadingPlayers.includes(name)) {
        setLoadingPlayers(prev => [...prev, name]);
        
        // Remove from loading after a delay or when avatar loads
        const player = gamePlayers[playerIndex];
        if (player.avatar_url) {
          const img = new Image();
          img.onload = () => {
            setLoadingPlayers(prev => prev.filter(p => p !== name));
          };
          img.src = player.avatar_url;
        } else {
          setTimeout(() => {
            setLoadingPlayers(prev => prev.filter(p => p !== name));
          }, 2000);
        }
      }
    });
  }, [playerNames, value, loadingPlayers]);

  // Auto-cycle through players when we have at least one
  useEffect(() => {
    if (players.some(p => p !== null)) {
      const interval = setInterval(() => {
        setSelectedIndex(prev => {
          let next = prev;
          do {
            next = (next + 1) % players.length;
          } while (!players[next] && next !== prev);
          return players[next] ? next : prev;
        });
      }, CYCLE_INTERVAL);
      
      return () => clearInterval(interval);
    }
  }, [players]);

  return (
    <Box sx={{
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '1rem',
      gap: '1rem',
      overflow: 'hidden',
    }}>
      <ArcadeTitle sx={{ fontSize: '1.5rem' }}>
        LOBBY CODE: {lobbyCode}
      </ArcadeTitle>

      <Box sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        overflow: 'hidden',
      }}>
        {selectedIndex !== -1 && players[selectedIndex] && (
          <>
            <IllustrationDisplay player={players[selectedIndex]} />
            <CharacterInfo player={players[selectedIndex]} />
          </>
        )}
      </Box>

      <Box sx={{
        display: 'grid',
        gridTemplateColumns: `repeat(${GRID_COLS}, 72px)`, // Exact width of slots
        gridTemplateRows: `repeat(${GRID_ROWS}, 72px)`, // Exact height of slots
        background: 'rgba(0, 0, 0, 0.5)',
        border: '3px solid rgba(0, 255, 255, 0.2)',
        borderRadius: '4px',
        overflow: 'hidden',
        marginBottom: '1rem',
        // Add bottom and right borders to complete the grid
        '& > div:nth-of-type(4n)': {
          borderRight: '3px solid rgba(0, 255, 255, 0.3)',
        },
        '& > div:nth-last-of-type(-n+4)': {
          borderBottom: '3px solid rgba(0, 255, 255, 0.3)',
        },
      }}>
        {players.map((player, index) => (
          <CharacterSlot
            key={index}
            player={player}
            isSelected={index === selectedIndex}
            isLoading={player && loadingPlayers.includes(player.fighter_name)}
          />
        ))}
      </Box>

      <button
        onClick={startHandler}
        disabled={disableButtons || playerNames.length === 0}
        className={`
          w-48 py-3 px-6 text-base font-arcade mb-2
          ${playerNames.length === 0 
            ? 'bg-gray-600 cursor-not-allowed' 
            : 'bg-gradient-to-r from-cyan-500 to-cyan-400 hover:from-cyan-400 hover:to-cyan-300'}
          text-black rounded-lg shadow-lg
          transform transition-all duration-200
          ${!disableButtons && 'hover:-translate-y-1 hover:shadow-xl'}
          disabled:opacity-50 disabled:cursor-not-allowed
        `}
      >
        START BATTLE
      </button>
    </Box>
  );
};

export default HostLobby;