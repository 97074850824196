import { doc } from 'firebase/firestore';
import { useDocument } from 'react-firebase-hooks/firestore';
import { Box, Typography, CircularProgress } from '@mui/material';
import { db } from './firebase';
import { useState, useEffect } from 'react';
import ErrorPage from './ErrorPage';
import HostLobby from './HostLobby';
import HostGame from './HostGame';
import LoadingScreen from './LoadingScreen';

const HostEntry = () => {
    const [errorText, setErrorText] = useState('');
    const [docId, setDocId] = useState('');
    const [startButtonClicked, setStartButtonClicked] = useState(false);

    useEffect(() => {
        const createGame = async () => {
            try {
                console.log("creating lobby...");
                const response = await fetch('https://create-game-http-meryawfipq-uc.a.run.app', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        gptModel: "gpt-4-turbo-preview",
                        style: "pixel art"
                    }),
                }); 
                const data = await response.json();
                setDocId(data.docId);
            } catch (error) {
                console.error(error);
                setErrorText(error.message);
            }
        };

        createGame();
    }, []); // Run once on component mount

    const startButtonClickedHandler = () => {
        setStartButtonClicked(true);
    }

    const docRef = docId ? doc(db, 'games', docId) : null;
    var [value, loading, error] = useDocument(
        docRef,
        {
            snapshotListenOptions: { includeMetadataChanges: true },
        }
    );

    const fighterNames = value && value.data().players.map(player => player.fighter_name);

    return (
        <Box sx={{
            minHeight: '100vh',
            background: 'linear-gradient(135deg, #1a1a2e 0%, #16213e 100%)',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative',
            overflow: 'hidden',
            fontFamily: '"Press Start 2P", system-ui',
            '&::before': {
                content: '""',
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                background: 'repeating-linear-gradient(45deg, transparent, transparent 10px, rgba(0,0,0,0.1) 10px, rgba(0,0,0,0.1) 20px)',
                animation: 'backgroundScroll 20s linear infinite',
            },
        }}>
            {!startButtonClicked && (
                <Box sx={{ zIndex: 1 }}>
                    {(loading || !value) && 
                        <LoadingScreen />
                    }
                    {value && 
                        <HostLobby 
                            docId={docId} 
                            lobbyCode={value.data().login_code} 
                            playerNames={fighterNames} 
                            startHandler={startButtonClickedHandler} 
                            disableButtons={startButtonClicked}
                        />
                    }
                </Box>
            )}

            {startButtonClicked && (
                <Box sx={{ zIndex: 1, width: '100%' }}>
                    <HostGame docRef={docRef} docValue={value} />
                </Box>
            )}

            <ErrorPage errorText={errorText}/>
            <ErrorPage errorText={error?.message}/>
        </Box>
    );
};

export default HostEntry;