import React from 'react';
import Christmas from './Christmas';

const GARDEN_EMOJIS = ['🌱', '🪴', '🌺', '🌸', '🌻', '🌿', '🍅', '🥬', '🥕', '🌳'];

const CORNER_DECORATIONS = [
  '🌱 🪴 🌺', // top-left
  '🌸 🌻 🌿', // top-right
  '🎁 🎄 🍅', // bottom-left
  '⭐ 🥬 🌳'  // bottom-right
];

const VIDEO_URL = "https://storage.googleapis.com/choose-your-fighter-8/Gen-3%20Alpha%20Turbo%202627676588%2C%20The%20woman%20disappears%2C%20468196251_1023236718%2C%20M%205.mp4";

export default function ChristmasDad() {
  return (
    <Christmas
      title="Dad"
      videoUrl={VIDEO_URL}
      certificateUrls={["https://storage.googleapis.com/choose-your-fighter-8/i-m-trying-to-find-you-a-prof-12-22-2024.png"]} // Empty array since we don't have images yet
      themeEmojis={GARDEN_EMOJIS}
      cornerEmojis={CORNER_DECORATIONS}
      showBook={false}
    />
  );
}