import React from 'react';
import { Box } from '@mui/material';
import { ArcadeTitle, ArcadeDots } from './ArcadeTypography';

const LoadingScreen = () => {
  return (
    <Box 
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
        gap: 4,
        position: 'relative',
      }}
    >
      <ArcadeTitle
        sx={{
          fontSize: { xs: '1.5rem', sm: '2rem', md: '3rem' },
          color: '#00ffff',
          textShadow: `
            2px 2px 0 #000,
            -2px -2px 0 #000,
            2px -2px 0 #000,
            -2px 2px 0 #000,
            0 0 8px rgba(0, 255, 255, 0.8),
            0 0 16px rgba(0, 255, 255, 0.4)
          `,
          animation: 'glitch 2s infinite',
          '@keyframes glitch': {
            '0%, 100%': {
              transform: 'translate(0)',
            },
            '33%': {
              transform: 'translate(-2px, 2px)',
              filter: 'hue-rotate(-30deg)',
            },
            '66%': {
              transform: 'translate(2px, -2px)',
              filter: 'hue-rotate(30deg)',
            }
          }
        }}
      >
        Creating Arena
      </ArcadeTitle>

      {/* Loading progress bar */}
      <Box
        sx={{
          width: '300px',
          height: '10px',
          background: 'rgba(0,0,0,0.3)',
          borderRadius: '5px',
          overflow: 'hidden',
          position: 'relative',
          '&::after': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            height: '100%',
            width: '30%',
            background: '#00ffff',
            animation: 'loadingBar 1.5s ease-in-out infinite',
            borderRadius: '5px',
            boxShadow: '0 0 20px rgba(0, 255, 255, 0.5)',
          },
          '@keyframes loadingBar': {
            '0%': { left: '-30%' },
            '100%': { left: '100%' }
          }
        }}
      />

      <ArcadeDots 
        color="#00ffff"
        sx={{
          '& span': {
            textShadow: '0 0 10px rgba(0, 255, 255, 0.5)',
          }
        }}
      />
    </Box>
  );
};

export default LoadingScreen;